<template>
    <div>

    <CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader class="">
      <CRow>
      <CCol col="6" xl="6">
        <h6 class="w-100 text-muted"><span v-if="!isNaN(id)">Menu položka </span>
          <span v-else>Pridávate menu</span> </h6>
         <h2 class="mb-0 page-title" v-if="menu.title">{{menu.title}}</h2>
         <h2 class="mb-0 page-title" v-else>Nová menu položka</h2>
      </CCol>
      <CCol col="6" xl="6">
           <div class="text-right system-options w-100 d-block" style="">
          </div>
      </CCol>
      </CRow>
      </CCardHeader>
      <CCardBody>

                    <a-card shadow type="secondary" style="margin:0; margin-top:30px" >
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                          <a-form-item label="Názov">
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="menu.title"
                                            />
                                      </a-form-item>
                                        </div>
                                        <div class="col-lg-12">
                                          <a-form-item label="URL">
                                            <a-input alternative=""
                                                        label="URL"
                                                        input-classes="form-control-alternative"
                                                        v-model="menu.url"
                                            />
                                            </a-form-item>
                                        </div>
                                        <div class="col-lg-12">
                                          <a-form-item label="Obrázok">
                                            <image-upload v-if="menu.image" :data="JSON.parse(menu.image)" title="Obrázok" @changed="(e) => onChildChange(e)"></image-upload>
                                            <image-upload v-else title="Obrázok" dat="image" @changed="(e) => onChildChange(e)"></image-upload>
                                          </a-form-item>
                                        </div>
                                        <div class="col-lg-12">
                                          <a-form-item label="Názov sekcie">
                                            <a-input alternative=""
                                                        label="Názov sekcie"
                                                        input-classes="form-control-alternative"
                                                        v-model="menu.section_title"
                                            />    </a-form-item>
                                        </div>
                                    </div>
                                </div>


                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                    </CCardBody>
                    </CCard>
                    </CCol>
                    </CRow>
    </div>
</template>
<script>
    import {API_URL} from "../../api";
    import {authHeader} from "../../_helpers";
    import {dataService} from "../../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import Image from "./Image";
    Vue.use(FormItem);

    let id = 0;
    export default {
        name: 'menuitem',
        components: { "image-upload":Image},
        props: ['mid','id'],
        data() {
            return {
                data: [],
                newField: {
                    title : null,
                    type : null,
                    options : [],
                    slug : null,
                    menuid : this.mid
                },
                loading: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                head: authHeader(),
                send: API_URL,
                colorsto: JSON.parse(localStorage.colors),
                menuId: {
                    uid: this.mid
                },
                menu: {
                    title: '',
                    menuid: this.mid,
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        },
        methods: {
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            onChildChange(value) {
                this.menu.image = JSON.stringify(value);
            },
            cancel() {
            },
            handleChange() {

            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },

            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            handleSubmit() {
                let $menu_data = this.menu;
                dataService.axiosPost(this.$options.name, $menu_data).then(results => {

                    if(results.data.message)
                    {this.menu = [];
                        this.$router.push('/menu-items/'+results.data.message) }

                });
            },
        },
        mounted() {
            if (!isNaN(this.id)) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.menu = results[0];

                });
            }
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .ant-select-selection--single
    {width:100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;}

</style>
